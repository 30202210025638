import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getCookie,
  createUrlAd,
  floatToCurrency,
  createUrlPhotoAd,
  createUrlIdc,
  getItemPosition,
  checkStringifiedJSON,
  generateSearchURL,
  generateFilterLocationFromCookie,
} from 'utils/utils'
import {
  SELLER_TYPE,
  COOKIES,
  VEHICLE_TYPE,
  COOKIE_NOTICE_TYPES,
} from 'utils/constants'
import WhiteBox from 'components/WhiteBox'
import CardVehicleDefault from 'components/CardVehicle/CardVehicleDefault'
import CardVehicleFeirao from 'components/CardVehicle/CardVehicleFeirao'
import CardVehicleKM from 'components/CardVehicle/CardVehicleKM'
import CardVehicleSignature from 'components/CardVehicle/CardVehicleSignature'
import CarouselContainer from 'components/CarouselContainer'
import HomePlaceholder from 'container/Home/HomePlaceHolder'
import Slider from 'react-slick'

import { withCookieNotice } from '@/store/context/cookie-notice-context'

import { cookieCategoryIsActive } from '@/modern/utils/cookies'

import { config } from 'config/config'
import { fetchRecommendedVehicles } from 'ducks/recommendedVehicles'

import './RecommendedVehicles.scss'

class RecommendedVehicles extends Component {
  constructor(props) {
    super(props)

    const { cookieNoticeContext } = this.props
    const { cookies } = cookieNoticeContext

    this.cookiesState = {
      sentLeads: cookieCategoryIsActive(cookies, COOKIES.SENT_LEADS),
    }

    this.state = {
      leadsIdsArr: this.cookiesState.sentLeads && getCookie(COOKIES.SENT_LEADS),
      recommendedVehiclesArr: [],
      callBackSent: false,
    }
  }

  settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1930,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  }

  settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1930,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  }

  componentDidMount() {
    this.setState({ fetched: true }, () => {
      this.fetchVehicles()
    })
  }

  componentDidUpdate(prevProps) {
    const { estadocidade } = this.props
    const { fetched } = this.state
    if (estadocidade != prevProps.estadocidade) {
      this.setState({ fetched: false })
    }
    if (!fetched) {
      this.setState({ fetched: true }, () => {
        setTimeout(() => {
          this.fetchVehicles()
        }, 10000)
      })
    }
    const oldCookies = prevProps.cookieNoticeContext.cookies
    const { cookies } = this.props.cookieNoticeContext

    if (
      JSON.stringify(oldCookies[COOKIE_NOTICE_TYPES.PROPOSALS]) !==
      JSON.stringify(cookies[COOKIE_NOTICE_TYPES.PROPOSALS])
    ) {
      this.cookiesState = {
        ...this.cookiesState,
        sentLeads: cookieCategoryIsActive(cookies, COOKIES.SENT_LEADS),
      }
    }
  }

  getLastSearchURL() {
    const { cookies } = this.props.cookieNoticeContext
    const isCookieCategoryActive = cookieCategoryIsActive(
      cookies,
      COOKIES.LAST_SEARCHES
    )

    if (isCookieCategoryActive) {
      let lastSearchesCookies = getCookie(COOKIES.LAST_SEARCHES)
      if (
        lastSearchesCookies &&
        typeof lastSearchesCookies === 'string' &&
        checkStringifiedJSON(lastSearchesCookies)
      ) {
        lastSearchesCookies = JSON.parse(lastSearchesCookies)
        if (
          lastSearchesCookies.length &&
          lastSearchesCookies[0] &&
          lastSearchesCookies[0].query
        ) {
          const _array = lastSearchesCookies[0].query
            .replace('?', '')
            .split('&')
          const _newArray = {}
          _newArray.tipoveiculo = VEHICLE_TYPE.CAR
          _array.map(item => {
            const _subArray = item.split('=')
            if (_subArray[0] == 'estadocidade')
              _newArray.estadocidade = generateFilterLocationFromCookie(
                decodeURIComponent(_subArray[1])
              )

            if (_subArray[0] == 'marca1')
              _newArray.marca1 = decodeURIComponent(_subArray[1])
            if (_subArray[0] == 'modelo1')
              _newArray.modelo1 = decodeURIComponent(_subArray[1])
          })

          if (this.props.vehicleType)
            _newArray.tipoveiculo =
              this.props.vehicleType == VEHICLE_TYPE.car
                ? VEHICLE_TYPE.CAR
                : VEHICLE_TYPE.BIKE

          _newArray.media = 'com fotos'

          if (this.props.hotDeal) _newArray.feirao = this.props.hotDeal

          return generateSearchURL(_newArray)
        }
      }
    }

    return false
  }

  fetchVehicles() {
    const { cookies } = this.props.cookieNoticeContext

    const {
      vehicleType,
      sellerID,
      recommendationTimestamp,
      dispatch,
      recommendedVehicles,
      uniqueId,
    } = this.props
    const { leadsIdsArr } = this.state
    const isCookieCategoryActive = cookieCategoryIsActive(
      cookies,
      COOKIES.LAST_SEARCHES
    )

    const lastSearchURls =
      isCookieCategoryActive && getCookie(COOKIES.LAST_SEARCHES)

    if (!lastSearchURls) {
      return false
    }

    const data = {
      url: encodeURIComponent(this.getLastSearchURL()),
      vehicleType,
      displayPerPage: 10,
      sellerID: sellerID ? sellerID : undefined,
      recommendationTimestamp,
    }

    dispatch(fetchRecommendedVehicles(data)).then(() => {
      if (recommendedVehicles.fetched) {
        let recommendations = recommendedVehicles.result.SearchResults

        if (leadsIdsArr) {
          recommendations = recommendations.filter(
            item => !leadsIdsArr.split(',').includes(`${item.UniqueId}`)
          )
        }

        if (uniqueId) {
          recommendations = recommendations.filter(
            item => uniqueId != item.UniqueId
          )
        }

        this.setState({
          recommendedVehiclesArr: recommendations,
          callBackSent: false,
        })
      } else {
        this.setState({ fetched: false })
      }
    })
  }

  renderRecommendations() {
    const maxResultsShown = 10
    const results = []
    const tracking = []
    const { vehicleType, origin } = this.props
    const { recommendedVehiclesArr } = this.state

    const recommendations = recommendedVehiclesArr.slice()

    recommendations.forEach(item => {
      if (results.length === maxResultsShown) return false
      const {
        PhotoPath,
        ListingType,
        Prices,
        Specification,
        HotDeal,
        Seller,
        UniqueId,
        IsElegibleVehicleInspection,
      } = item

      const listPhotos = []
      if (item.Media && item.Media.Photos && item.Media.Photos.length >= 1) {
        item.Media.Photos.map(_photo => {
          listPhotos.push(
            createUrlPhotoAd(
              _photo.PhotoPath,
              vehicleType,
              ListingType,
              config.photoSearchResultBig
            )
          )
        })
      } else listPhotos.push(config.noPhoto)

      // Array para parametros extras na Url do detalhe do anuncio
      let extraParam = [...[origin ? `orirecom=${origin}` : undefined]]
      extraParam = extraParam
        .filter(itemParam => itemParam != undefined)
        .join('&')

      const data = {
        IsElegibleVehicleInspection,
        uniqueId: UniqueId,
        listPhotos,
        linkCard: `${createUrlIdc(
          createUrlAd(item, vehicleType, extraParam),
          'webmotors',
          'recomendacao',
          'recomendacoes:comprar'
        )}&lkid=2218`,
        linkThumb: PhotoPath
          ? createUrlPhotoAd(
              PhotoPath,
              vehicleType,
              ListingType,
              config.photoSearchResultBig
            )
          : config.noPhoto,
        make: Specification.Make.Value,
        model: Specification.Model.Value,
        title: `${Specification.Make.Value} ${Specification.Model.Value}`,
        description:
          Specification.Version && Specification.Version.Value
            ? Specification.Version.Value
            : Specification.BodyType,
        price: `R$ ${Prices.Price ? floatToCurrency(Prices.Price, 0) : '0'}`,
        year: `${Specification.YearFabrication}/${Specification.YearModel}`,
        km: `${
          Specification.Odometer
            ? floatToCurrency(Specification.Odometer, 0)
            : '0'
        } km`,
        city: `${Seller.City} - ${Seller.State.split(' ')
          .pop()
          .replace(/[()]/g, '')}`,
        hotDeal: HotDeal,
        tag: {
          alt: `${Specification.Make.Value}-${Specification.Model.Value}-${Specification.YearFabrication}`,
          title: `${Specification.Make.Value}-${Specification.Model.Value}${
            VEHICLE_TYPE.car === vehicleType
              ? `${
                  Specification.Version && Specification.Version.Value
                    ? `-${Specification.Version.Value}`
                    : ''
                }`
              : ''
          }`,
        },
      }

      if (config.signatureIds.includes(Seller.Id)) {
        data.price = `R$ ${item.LongComment}`
        results.push(
          <div key={UniqueId} className="slick-padding">
            <WhiteBox className="WhiteBox--card-vehicle">
              <CardVehicleSignature
                refs="cardResult"
                id={`CardVehicleSignature_${UniqueId}`}
                data={data}
              />
            </WhiteBox>
          </div>
        )
        tracking.push(item)
        return false
      }
      if (HotDeal) {
        results.push(
          <div key={UniqueId} className="slick-padding">
            <WhiteBox className="WhiteBox--card-vehicle">
              <CardVehicleFeirao
                refs="cardResult"
                id={`CardVehicleFeirao_${UniqueId}`}
                data={data}
              />
            </WhiteBox>
          </div>
        )
        tracking.push(item)
        return false
      }
      if (
        Seller.AdType.id === SELLER_TYPE.LOJA.ID ||
        Seller.AdType.id === SELLER_TYPE.CONCESSIONARIA.ID ||
        Seller.AdType.id === SELLER_TYPE.PESSOA_FISICA.ID
      ) {
        results.push(
          <div key={UniqueId} className="slick-padding">
            <WhiteBox className="WhiteBox--card-vehicle">
              <CardVehicleDefault
                refs="cardResult"
                id={`CardVehicle_${UniqueId}`}
                data={data}
              />
            </WhiteBox>
          </div>
        )
        tracking.push(item)
        return false
      }
      if (Seller.AdType.id === SELLER_TYPE.MONTADORA.ID) {
        results.push(
          <div key={UniqueId} className="slick-padding">
            <WhiteBox className="WhiteBox--card-vehicle">
              <CardVehicleKM
                refs="cardResult"
                id={`CardVehicleKM_${UniqueId}`}
                data={data}
              />
            </WhiteBox>
          </div>
        )
        tracking.push(item)
        return false
      }
      return false
    })
    if (!this.state.callBackSent) {
      const trackingText = tracking.length ? getItemPosition(tracking).text : ''
      this.props.callBack &&
        results.length &&
        this.props.callBack({
          results: trackingText,
          id: this.props.sellerID,
        })
      this.setState({ callBackSent: true })
    }
    return results
  }

  render() {
    const { recommendedVehiclesArr } = this.state
    const { recommendedVehicles, filtersOpen, id, title, customSettings } =
      this.props

    const usedSetting = !filtersOpen ? this.settings : this.settings2

    const setting = {
      ...usedSetting,
      ...customSettings,
    }

    if (recommendedVehiclesArr.length) {
      return recommendedVehicles.fetched ? (
        <CarouselContainer id={id || ''} title={title || ''}>
          <div className="ContainerCardVehicle RecommendedVehicles">
            <Slider {...setting}>{this.renderRecommendations()}</Slider>
          </div>
        </CarouselContainer>
      ) : (
        <HomePlaceholder />
      )
    }

    return null
  }
}

RecommendedVehicles.defaultProps = {
  customSettings: {},
}

export default withCookieNotice(
  connect(store => ({
    recommendedVehicles: store.recommendedVehiclesState,
  }))(RecommendedVehicles)
)
