export const PAGE_NAME = {
  HOME: 'HOME',
  SEARCH: 'RESULTADO DE BUSCA',
  HOTDEAL: 'FEIROES',
  RECAPTURE: 'REPESCAGEM',
  ADDETAIL: 'DETALHE DE ANUNCIO',
  NOTFOUND: 'NAO ENCONTRADO',
}

export const VEHICLE_TYPE = {
  CAR: 'carros',
  BIKE: 'motos',
  car: 'car',
  bike: 'bike',
}

export const VEHICLE_TYPE_ENUM = {
  CAR: 1,
  BIKE: 2,
}

export const AD_TYPE = {
  USED: 'U',
  NEW: 'N',
}

export const DISPLAY_CARD = {
  column: '1',
  grid: '2',
}

export const AD_TYPE_ENUM = {
  ALL: 84,
  USED: 85,
  NEW: 78,
}

export const SELLER_TYPE = {
  PESSOA_FISICA: { ID: 4, VALUE: 'Pessoa Física' },
  CONCESSIONARIA: { ID: 2, VALUE: 'Concessionária' },
  LOJA: { ID: 1, VALUE: 'Loja' },
  MONTADORA: { ID: 6, VALUE: 'Montadora' },
}

export const SELLER_TYPE_ENUM = {
  PF: 'PF',
  PJ: 'PJ',
}

export const DAY_OF_WEEK = {
  SUNDAY: { VALUE: 0, DESCRIPTION: 'Domingo' },
  MONDAY: { VALUE: 1, DESCRIPTION: 'Segunda' },
  TUESDAY: { VALUE: 2, DESCRIPTION: 'Terça' },
  WEDNESDAY: { VALUE: 3, DESCRIPTION: 'Quarta' },
  THURSDAY: { VALUE: 4, DESCRIPTION: 'Quinta' },
  FRIDAY: { VALUE: 5, DESCRIPTION: 'Sexta' },
  SATURDAY: { VALUE: 6, DESCRIPTION: 'Sábado' },
  HOLIDAYS: { VALUE: 7, DESCRIPTION: 'Feriados' },
}

export const STATES = [
  { uf: 'AC', name: 'Acre' },
  { uf: 'AL', name: 'Alagoas' },
  { uf: 'AP', name: 'Amapá' },
  { uf: 'AM', name: 'Amazonas' },
  { uf: 'BA', name: 'Bahia' },
  { uf: 'CE', name: 'Ceará' },
  { uf: 'DF', name: 'Distrito Federal' },
  { uf: 'ES', name: 'Espírito Santo' },
  { uf: 'GO', name: 'Goiás' },
  { uf: 'MA', name: 'Maranhão' },
  { uf: 'MT', name: 'Mato Grosso' },
  { uf: 'MS', name: 'Mato Grosso do Sul' },
  { uf: 'MG', name: 'Minas Gerais' },
  { uf: 'PA', name: 'Pará' },
  { uf: 'PB', name: 'Paraíba' },
  { uf: 'PR', name: 'Paraná' },
  { uf: 'PE', name: 'Pernambuco' },
  { uf: 'PI', name: 'Piauí' },
  { uf: 'RJ', name: 'Rio de Janeiro' },
  { uf: 'RN', name: 'Rio Grande do Norte' },
  { uf: 'RS', name: 'Rio Grande do Sul' },
  { uf: 'RO', name: 'Rondônia' },
  { uf: 'RR', name: 'Roraima' },
  { uf: 'SC', name: 'Santa Catarina' },
  { uf: 'SP', name: 'São Paulo' },
  { uf: 'SE', name: 'Sergipe' },
  { uf: 'TO', name: 'Tocantins' },
]

export const MESSAGE_LEAD = {
  ONE: 'Olá, tenho interesse no veículo. Por favor entre em contato.',
  FINANCING:
    'Olá, realizei uma simulação de financiamento e tenho interesse no seu veículo. Por favor entre em contato',
}

export const TYPE_RESULT_LIST = {
  GRID: { ID: '1', DESCRIPTION: 'Cards Grid' },
  LIST: { ID: '2', DESCRIPTION: 'Cards Lista' },
}

export const SELLERSHIELD = {
  P: '(P)',
  M: '(M)',
  G: '(G)',
}

export const SYSTEM = {
  WEBMOTORS: 'WebMotors',
  COMPREAUTO: 'CompreAuto',
  MEUCARANGO: 'MeuCarango',
  BLUCARROS: 'BluCarros',
  JOINVILLECARROS: 'JoinvilleCarros',
  FLORIPACARROS: 'FloripaCarros',
  ITAJAICARROS: 'ItajaiCarros',
}

export const CHANNEL = {
  WEBMOTORS: 48,
  MEUCARANGO: 47,
  COMPREAUTO: 49,
  FEIRAO: 56,
  BLUCARROS: 60,
  FLORIPACARROS: 59,
  JOINVILLECARROS: 62,
  ITAJAICARROS: 63,
  BUSCACARROS: 73,
}
export const CHANNEL_REPORT = {
  SITE: 83,
  BACKOFFICE: 66,
  MEUCARANGO: 82,
  MOBILE: 77,
  APLICATIVO: 65,
  COMPREAUTO: 67,
}

export const UBER_CATEGORY = [
  { Url: 'uber' },
  { Url: 'uberblack' },
  { Url: 'uberselect' },
  { Url: 'uberx' },
]

export const ORIGIN_PAGES = {
  HOME: { KEY: 'HOME', DESCRIPTION: 'portal homepage', URL: 'portal-home' },
  OFERTAS: {
    KEY: 'OFERTAS',
    DESCRIPTION: 'portal ofertas',
    URL: 'portal-ofertas',
  },
  BUSCA: {
    KEY: 'BUSCA',
    DESCRIPTION: 'portal resultado de busca',
    URL: 'portal-busca',
  },
  DETALHE: {
    KEY: 'DETALHE',
    DESCRIPTION: 'portal detalhe do anuncio',
    URL: 'portal-detalhe',
  },
  RECAPTURE: {
    KEY: 'RECAPTURE',
    DESCRIPTION: 'portal repescagem',
    URL: 'portal-repescagem',
  },
  DISABLED: {
    KEY: 'DISABLED',
    DESCRIPTION: 'portal anuncio desativado',
    URL: 'portal-disabled',
  },
  NOTFOUND: {
    KEY: 'NOTFOUND',
    DESCRIPTION: 'portal pagina nao encontrada',
    URL: 'portal-notfound',
  },
}

export const TEXT = {
  SIM: 'sim',
  NAO: 'nao',
}

export const FAIR = {
  megaFair: {
    id: 1085,
    name: 'black-feirao',
  },
  noFair: {
    id: 2,
    name: 'nao-feirao',
  },
  blackFair: {
    name: 'black-feirao',
  },
}

export const MODALITY_TYPE = {
  NONE: 'nenhum', // 0
  ALL: 'all', // 1,
  CAR: 'car', // 2
  BIKE: 'bike', // 3
}

export const COOKIES = {
  LAST_SEARCHES: 'WebMotorsLastSearches',
  SENT_LEADS: 'WebMotorsSentLeads',
  DATA_FORM_LEADS: 'WebMotorsDataFormLeads',
  FORM_DATA: 'WebMotorsDataForm',
  VEHICLE_TYPE: 'WebMotorsVehicleType',
  VISITOR: 'WebMotorsVisitor',
  FILTER_SEARCH: 'WMLastFilterSearch',
  CONTINUE_FINANCING: 'wm_enquire_loan',
  CARD_PREFERENCES: 'WebMotorsCardPreferences',
  TRACKING_FROM: 'WebMotorsTrackingFrom',
  SEARCH_DATALAYER: 'WebMotorsSearchDataLayer',
  CHANNEL: 'WebMotorsChannel',
  ONBOARDING: 'WebMotorsOnboarding',
  GEOLOCATION: 'WebMotorsLocation',
  JWT_TOKEN: 'webmotors.jwt.token',
  USER_PROPERTIES: 'webmotors.user.properties',
}

export const COOKIE_NOTICE_TYPES = {
  ESSENTIALS: 'essentials',
  PROPOSALS: 'proposals',
  SEARCH: 'search',
  MARKETING: 'marketing',
  EXPERIENCE: 'experience',
}

export const COOKIE_NOTICE_GROUPS = [
  {
    key: COOKIE_NOTICE_TYPES.ESSENTIALS,
    cookies: [COOKIES.JWT_TOKEN, COOKIES.USER_PROPERTIES],
  },
  {
    key: COOKIE_NOTICE_TYPES.PROPOSALS,
    cookies: [
      COOKIES.FORM_DATA,
      COOKIES.CONTINUE_FINANCING,
      COOKIES.SENT_LEADS,
    ],
  },
  {
    key: COOKIE_NOTICE_TYPES.SEARCH,
    cookies: [
      COOKIES.TRACKING_FROM,
      COOKIES.SEARCH_DATALAYER,
      COOKIES.GEOLOCATION,
      COOKIES.CARD_PREFERENCES,
      COOKIES.FILTER_SEARCH,
      COOKIES.VEHICLE_TYPE,
      COOKIES.LAST_SEARCHES,
    ],
  },
  {
    key: COOKIE_NOTICE_TYPES.MARKETING,
    cookies: [COOKIES.VISITOR, COOKIES.CHANNEL, COOKIES.DATA_FORM_LEADS],
  },
]

export const CATALOG_URL_MATCH = 'webmotors-catalogo.s3.amazonaws.com'
export const CATALOG_URL_REPLACE = 'webmotors.com.br'
export const CATALOG_SPACE_MATCH = /\s/g
export const CATALOG_SPACE_REPLACE = '%20'

export const zeroKmModels = [
  'FRONTIER',
  'KICKS',
  'ARGO',
  'CRONOS',
  'MOBI',
  'STRADA',
  'TORO',
  'CIVIC',
  'ONIX',
  'COROLLA',
]

export const lkidListFooterByArea = {
  BUY: {
    USED_CARS: 1075,
    NEW_CARS: 1076,
    CARS_BY_STATE: null,
    USED_MOTORCYCLES: 1077,
    NEW_MOTORCYCLES: 1078,
    MOTORCYCLES_BY_STATE: null,
    SELF_PAID: 1079,
    VISTORIADO: 1578
  },
  SELL: {
    SELL_MY_VEHICLE: 1080,
    SELL_MY_MOTORCYCLE: 1081,
    MANAGER_MY_AD: 1082,
    SELF_PAID: 1083,
    DOES_EVERYTHING: 1363,
  },
  SERVICES: {
    FIPE_TABLE_WEBMOTORS: 1084,
    ZERO_KM: 1085,
    WM1_NEWS: 1086,
    SELF_PAID: 1087,
    FINANCING: 1088,
    CONSORTIUM: 1089,
    SAFE: 1090,
    DEALER_PLATFORM: 1091,
    AUTO_INSIGHTS: 1092,
    DOES_EVERYTHING: 1369,
    VISTORIADO: 1580,
  },
  HELP: {
    WM_HELP_SERVICE: 1439,
    BUYERS_ADVERTISERS: 1093,
    HOW_TO_SELL: 1094,
    HOW_TO_BUY: 1095,
    DEALERSHIP_STORES: 1096,
    SITE_MAP: null,
    CONSUMER_PROTECTION_CODE: 1097,
    COOKIES_MANAGER: 1098,
    WORK_WITH_US: 1099,
    ABOUT_US: null,
    SAFE: null,
    PRIVACY_POLICY: null,
    LGPD: null,
  },
  PARTNERS: {
    CARSALES: null,
    LOOP_CARS: null,
  },
}

export const REQUEST_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
}
